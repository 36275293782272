import { Component, OnInit } from '@angular/core';
import { AuthGuard } from 'app/guards/auth.guard';
import { User } from 'app/classes/user';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-new-grades-jornadas',
  templateUrl: './new-grades-jornadas.component.html',
})
export class NewGradesJornadasComponent implements OnInit {

  empresaAtiva: number;
  unidadeAtiva: number;
  loggedUser: User;
  safeUrl: SafeResourceUrl;

  constructor(private authGuard: AuthGuard, private sanitizer: DomSanitizer) {
    this.loggedUser =  this.authGuard.loggedUser();
    this.empresaAtiva = this.loggedUser.selectedCompany.id;
    this.unidadeAtiva = this.loggedUser.selectedBranch.id;
    const url = `https://d3ngs1ybei3jyp.cloudfront.net/grades-jornada/${this.empresaAtiva}/${this.unidadeAtiva}`;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {
    
  }

}
