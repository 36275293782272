import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-consulta-jornada',
  templateUrl: './new-consulta-jornada.component.html',
})
export class NewConsultaJornadaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
