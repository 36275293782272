import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-detalhe-inconformidade',
  templateUrl: './new-detalhe-inconformidade.component.html',
})
export class NewDetalheInconformidadeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
